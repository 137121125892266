import React from 'react';
import styled from 'styled-components';

import {
	Enum_Componentbaselink_Size,
	Enum_Componentbaselink_Variant,
	Enum_Componentgeneralbanner_Contentalign,
	Enum_Componentgeneralbanner_Style,
} from '@schema';

import { media } from '../../styles/mixins';
import { Banner } from '../banner';

export const GetInTouch = ({ images }) => {
	return (
		<Container>
			<Wrapper>
				<Banner
					contentAlign={Enum_Componentgeneralbanner_Contentalign.Center}
					id={'openchannel get in touch'}
					style={Enum_Componentgeneralbanner_Style.Transparent}
					title={`{"time":1661856452288,"blocks":[{"id":"_fCinARX92","type":"paragraph","data":{"text":"Contact us for a free IT consultation","alignment":"center"}}],"version":"2.23.2"}`}
					description={`{"time":1661856452288,"blocks":[{"id":"_fCinARX92","type":"paragraph","data":{"text":"Fill out the form below to receive a free consultation and find out how Softkit can help your business grow.","alignment":"center"}}],"version":"2.23.2"}`}
					image={images.find((image) => image.name === 'get_in_touch').image}
					link={{
						id: 'openchannel get in touch link',
						variant: Enum_Componentbaselink_Variant.Primary,
						text: `Get in touch with us`,
						external: false,
						size: Enum_Componentbaselink_Size.Medium,
					}}
				/>
			</Wrapper>
		</Container>
	);
};

const Container = styled.div`
	background: ${(p) => p.theme.colors.background};
`;

const Wrapper = styled.div`
	max-width: 1460px;
	min-height: auto;
	${media.xl`
        margin: 0 auto;
	`}
	> div {
		z-index: 1;
		min-height: auto;
		padding: 100px 16px;
		${media.md`
            padding: 140px 24px;
	    `}
		${media.xl`
            padding: 160px 80px;
	    `}
        img {
			object-fit: contain !important;
		}
	}
`;
