import * as React from 'react';
import { Helmet } from 'react-helmet';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import latinNormal400 from '@fontsource/prompt/files/prompt-latin-400-normal.woff2';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import latinNormal500 from '@fontsource/prompt/files/prompt-latin-500-normal.woff2';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import latinNormal600 from '@fontsource/prompt/files/prompt-latin-600-normal.woff2';
import {
	ComponentGeneralFaq,
	ComponentSeoGlobalMeta,
	ComponentSeoMeta,
	SectionEntity,
} from '@schema';

interface SeoProps {
	meta: ComponentSeoMeta;
	imagesPreload?: [string?];
	globalMeta?: ComponentSeoGlobalMeta[];
	url: string;
	faq?: SectionEntity;
}

export const Seo = ({ meta, globalMeta, imagesPreload = [], url, faq = undefined }: SeoProps) => {
	let origin = 'https://www.softkit.dev';
	const replaceQuotes = (text) => text.replaceAll('"', "'");

	const getBreadcrumbs = () => {
		return url
			.replace(origin, '')
			.split('/')
			.map((path, index) => {
				if (index === 0) {
					return `{
				  "@type": "ListItem",
					"position": ${index + 1},
					"name": "Home",
					"item": "${origin}/"
				}`;
				} else if (path) {
					origin = origin + '/' + path;
					return `{
				  "@type": "ListItem",
					"position": ${index + 1},
					"name": "${path
						.replaceAll('-', ' ')
						.split(/\s+/)
						.map((word) => word[0].toUpperCase() + word.substring(1))
						.join(' ')}",
					"item": "${origin}/"
				}`;
				}
			})
			.toString()
			.slice(0, -1);
	};

	function isFaq(faq): faq is ComponentGeneralFaq[] {
		return (faq as ComponentGeneralFaq[]) !== undefined;
	}
	return (
		<Helmet title={meta?.title || 'Title'}>
			<html lang="en" />
			{/* Global Meta Tags */}
			{globalMeta.map(({ id, name, property, content }) => (
				<meta
					key={id}
					content={content}
					{...(name ? { name } : {})}
					{...(property ? { property } : {})}
				/>
			))}
			{/* Primary Meta Tags */}
			<meta name="title" content={meta?.title} />
			<meta name="description" content={meta?.description} />
			{/* Open Graph / Facebook */}
			<meta property="og:type" content={meta?.og_facebook?.type} />
			<meta property="og:url" content={meta?.og_facebook?.url} />
			<meta property="og:title" content={meta?.og_facebook?.title} />
			<meta property="og:description" content={meta?.og_facebook?.description} />
			<meta property="og:image" content={meta?.og_facebook?.image.data?.attributes?.url} />
			{/* Twitter */}
			<meta property="twitter:card" content={meta?.twitter?.card} />
			<meta property="twitter:url" content={meta?.twitter?.url} />
			<meta property="twitter:title" content={meta?.twitter?.title} />
			<meta property="twitter:description" content={meta?.twitter?.description} />
			<meta property="twitter:image" content={meta?.twitter?.image?.data?.attributes?.url} />
			<meta name="color-scheme" content="dark" />
			<link rel="dns-prefetch" href="https://www.googletagmanager.com/" />
			<link rel="preconnect" href="https://www.googletagmanager.com/" />
			<link rel="dns-prefetch" href="https://www.google-analytics.com/" />
			<link rel="preconnect" href="https://www.google-analytics.com/" />
			<link
				// rel="preload"
				href={latinNormal600}
				as="font"
				type="font/woff2"
				crossOrigin={'anonymous'}
			/>
			<link
				// rel="preload"
				href={latinNormal500}
				as="font"
				type="font/woff2"
				crossOrigin={'anonymous'}
			/>
			<link
				// rel="preload"
				href={latinNormal400}
				as="font"
				type="font/woff2"
				crossOrigin={'anonymous'}
			/>
			<script type="application/ld+json">
				{`{
				"@context": "https://schema.org",
				"@type": "BreadcrumbList",
				"itemListElement": [
					${getBreadcrumbs()}
				]}
				`}
			</script>
			{imagesPreload.filter(Boolean).map((img, k) => (
				<link key={k} rel="preload" href={img} as="image" crossOrigin="anonymous" />
			))}
			{isFaq(faq?.attributes?.primaryComponent) ? (
				<script type="application/ld+json">
					{`
					{
						"@context": "http://schema.org",
						"@type": "FAQPage",
						"mainEntity": [${faq.attributes.primaryComponent.map(
							(detail) => `{
								"@type": "Question",
								"name": "${replaceQuotes(detail.question)}",
								"acceptedAnswer": {
									"@type": "Answer",
									"text": "${replaceQuotes(JSON.parse(detail.answer).blocks[0].data.text)}"
								}
							}`,
						)}]
					}
				`}
				</script>
			) : null}
		</Helmet>
	);
};
