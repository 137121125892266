import React from 'react';
import { navigate } from 'gatsby';

import { TemplateProps } from '@templates/types';
import { PopupProvider } from '@context/popup-context';

import { DistilAbout } from '../../components/distil/about';
import { DistilFeatures } from '../../components/distil/features';
import { DistilHero } from '../../components/distil/hero';
import { DistilItems } from '../../components/distil/items';
import { DistilOvercome } from '../../components/distil/overcome';
import { DistilSolutions } from '../../components/distil/solutions';
import { GetInTouch } from '../../components/get-in-touch';
import { ReviewSection } from '../../components/review';
import { Popup } from '../../components/popup';
import { Seo } from '../../components/shared/seo';
import MainLayout from '../../layouts/main-layout';

export const DistilAiTemplate = (props: TemplateProps) => {
	const { page, menu, header, footer, cookieBanner, theme, ctaPopup, seo } = props.pageContext;
	const attributes = page.attributes;

	if (!page) {
		return navigate('/404');
	}
	const { GlobalMeta } = seo.data.attributes;

	return (
		<MainLayout
			menu={menu}
			header={header}
			footer={footer}
			cookieBanner={cookieBanner}
			overflow="hidden"
			theme={theme}
			popup={ctaPopup}
		>
			<Seo meta={page.attributes.meta} globalMeta={GlobalMeta} url={page.attributes.url} />
			{attributes && (
				<PopupProvider>
					{ctaPopup?.data && (
						<Popup
							form={ctaPopup.data.attributes.form}
							formResponse={ctaPopup.data.attributes.formResponse}
						/>
					)}
					<DistilHero
						data={attributes.content.hero}
						images={attributes.images}
						path={attributes.url}
					/>
					<DistilAbout data={attributes.content.section_1} images={attributes.images} />
					<DistilFeatures data={attributes.content.section_2} images={attributes.images} />
					<DistilOvercome data={attributes.content.section_3} />
					<DistilSolutions data={attributes.content.section_4} images={attributes.images} />
					<DistilItems data={attributes.content.section_5} />
					{attributes?.sections?.data[0] && <ReviewSection data={attributes.sections.data[0]} />}
					<GetInTouch images={attributes.images} />
				</PopupProvider>
			)}
		</MainLayout>
	);
};
