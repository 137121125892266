import React from 'react';
import styled from 'styled-components';

import SectionRendererTemplate from '@templates/renderer/section-renderer';

import { media } from '../../styles/mixins';

export const ReviewSection = ({ data }) => {
	return (
		<Wrapper>
			<SectionRendererTemplate section={data} />
		</Wrapper>
	);
};

const Wrapper = styled.div`
	background: ${(p) => p.theme.colors.softkitBG};
	color: ${(p) => p.theme.colors.white};
	> section {
		> div {
			> div {
				> div:last-child {
					min-width: 100%;
					> div {
						min-height: fit-content;
						height: fit-content;
						background: ${(p) => p.theme.colors.transparentBlack};
						position: relative;
						padding: 70px 20px;
						${media.xl`
							padding: 100px 120px;
						`}
						> div:last-child {
							> div:first-child {
								${media.xl`
									height: 0;
								`}
								> a {
									position: absolute;
									top: 0;
									right: 0;
									height: fit-content;
									border-radius: 0;
									padding: 16px;
									z-index: 5;
									background: ${({ theme }) => theme.gradients.button.hover.primary};
									-webkit-background-clip: text;
									-webkit-text-fill-color: transparent;
									span {
										background: ${({ theme }) => theme.gradients.button.hover.primary};
										-webkit-background-clip: text;
										-webkit-text-fill-color: transparent;
									}
								}
								> div {
									${media.xl`
										position: absolute;
										z-index: 5;
										height: fit-content;
										bottom: 100px;
										right: 120px;
										img {
											bottom: 16px;
										}
									`}
								}
							}
						}
					}
				}
			}
		}
	}
`;
