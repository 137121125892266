import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '../../styles/mixins';
import { SkImage } from '../shared/image';

export const DistilFeatures = ({ data, images }) => {
	return (
		<Wrapper>
			<MainTitle>{data.title}</MainTitle>
			<Items>
				{data.items.map((item, index) => (
					<Row key={item.title}>
						<Item data-type={item.type} data-left={(index + 1) % 2 === 0 ? 'true' : 'false'}>
							{item.type === 'default' && (
								<>
									<TextWrapper>
										<Title>{item.title}</Title>
										<Text>{item.text}</Text>
									</TextWrapper>
									<ImageWrapper data-left={(index + 1) % 2 === 0 ? 'true' : 'false'}>
										<SkImage {...images.find((image) => image.name === item.image).image} />
									</ImageWrapper>
								</>
							)}
							{item.type === 'color' && (
								<Color>
									<TextWrapper>
										<Title>{item.title}</Title>
										<Text>{item.text}</Text>
									</TextWrapper>
									<Browser>
										<SkImage {...images.find((image) => image.name === item.image).image} />
									</Browser>
									<Pattern>
										<SkImage {...images.find((image) => image.name === item.pattern).image} />
									</Pattern>
								</Color>
							)}
							{item.type === 'center' && (
								<Color>
									<TextWrapper>
										<Title>{item.title}</Title>
										<Text>{item.text}</Text>
									</TextWrapper>
									<Pattern>
										<SkImage {...images.find((image) => image.name === item.pattern).image} />
									</Pattern>
									<LeftImage>
										<SkImage {...images.find((image) => image.name === item.image1).image} />
									</LeftImage>
									<RightImage>
										<SkImage {...images.find((image) => image.name === item.image2).image} />
									</RightImage>
								</Color>
							)}
						</Item>
					</Row>
				))}
			</Items>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: 80px 0 0 0;
	gap: 24px;
	${media.md`
		padding: 60px 0 0 0;
        gap: 40px;
	`}
	${media.xl`
		padding: 80px 0 0 0;
        gap: 60px;
	`}
`;

const MainTitle = styled.p`
	font-weight: 600;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h4};
	`}
	line-height: 32px;
	color: ${(p) => p.theme.colors.headerText};
	letter-spacing: 0.01em;
	${media.md`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h3};
		`}
		line-height: 48px;
	`}
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h1};
		`}
		line-height: 88px;
	`}
	width: 100%;
	text-align: center;
`;

const Items = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
	${media.md`
        gap: 0;
    `}
`;

const Row = styled.div`
	position: relative;
`;

const Item = styled.div`
	&[data-type='default'] {
		display: flex;
		gap: 16px;
		padding: 0 16px;
		flex-direction: column-reverse;
		${media.md`
            flex-direction: row;
            padding: 0 24px;
            gap: 0;
        `}
		${media.xl`
            padding: 0 80px;
        `}
        ${media.xxl`
            padding: 0;
            max-width: 1460px;
            margin: 0 auto;
        `}
		> div {
			${media.md`
                width: 50%;  
            `}
		}
		> div:first-child {
			${media.md`
                padding-right: 24px;
            `}
			${media.xl`
                padding-right: 100px;
            `}
		}
		&[data-left='true'] {
			${media.md`
                flex-direction: row-reverse;
            `}
			> div:first-child {
				${media.md`
                padding: 0 0 0 24px;
            `}
				${media.xl`
                padding: 0 0 0 100px;
            `}
			}
		}
	}
	&[data-type='color'] {
		background: ${(p) => p.theme.colors.blue};
		border-radius: 24px;
		overflow: hidden;
		position: relative;
		margin-bottom: -24px;
		${media.md`
            margin-bottom: 0;
        `}
		p {
			color: ${(p) => p.theme.colors.white};
		}
		${media.xl`
            border-radius: 40px;
        `}
	}
	&[data-type='center'] {
		background: ${(p) => p.theme.colors.greenBg};
		border-radius: 24px;
		overflow: hidden;
		position: relative;
		height: 530px;
		display: flex;
		align-items: center;
		justify-content: center;
		p {
			color: ${(p) => p.theme.colors.white};
			text-align: center;
		}
		margin-bottom: -24px;
		${media.md`
            height: 780px;
            margin-bottom: 0;
            > div {
                > div:first-child{
                    width: 380px;
                }
            }
        `}
		${media.xl`
            border-radius: 40px;
            height: 800px;
            > div {
                display: flex;
                > div:first-child{
                    width: 580px;
                }
            }
        `}
	}
`;

const TextWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	position: relative;
	z-index: 5;
	${media.md`
        height: fit-content;
        margin: auto 0;
        gap: 16px;
    `}
`;

const Title = styled.p`
	font-weight: 600;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h4};
	`}
	line-height: 32px;
	color: ${(p) => p.theme.colors.headerText};
	letter-spacing: 0.01em;
	${media.md`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t6};
		`}
		line-height: 40px;
	`}
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h3};
		`}
		line-height: 48px;
	`}
`;

const Text = styled.p`
	font-weight: 400;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t2};
	`}
	line-height: 20px;
	color: ${(p) => p.theme.colors.headerText};
	${media.md`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t3};
		`}
		line-height: 22px;
	`}
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h5};
		`}
		line-height: 28px;
	`}
`;

const Pattern = styled.div`
	position: absolute;
	height: 650px;
	left: 50%;
	transform: translate(-50%);
	bottom: -10px;
	> img {
		width: auto;
		height: 100%;
		max-width: fit-content;
	}
	${media.md`
        height: 1000px;
    `}
`;

const ImageWrapper = styled.div`
	height: 320px;
	position: relative;
	@media (min-width: 490px) and (max-width: 767px) {
		position: inherit;
	}
	${media.md`
        height: 365px;
    `}
	${media.xl`
        height: 640px;
    `}
    @media (min-width: 1900px) {
		position: inherit;
	}
	> img {
		width: auto;
		height: 100%;
		position: absolute;
		max-width: fit-content;
		left: 0;
		@media (min-width: 490px) and (max-width: 767px) {
			height: 320px;
			right: 0;
			left: auto;
		}
		@media (min-width: 1900px) {
			right: 0;
			left: auto;
		}
	}
	&[data-left='true'] {
		> img {
			right: 0;
			left: auto;
			@media (min-width: 490px) and (max-width: 767px) {
				left: 0;
				right: auto;
			}
			@media (min-width: 1900px) {
				left: 0;
				right: auto;
			}
		}
	}
`;

const Browser = styled.div`
	position: relative;
	z-index: 5;
	> img {
		width: 100%;
		height: auto;
	}
	${media.md`
        padding: 0 36px;
    `}
`;

const Color = styled.div`
	padding: 40px 16px;
	display: flex;
	flex-direction: column-reverse;
	gap: 24px;
	${media.md`
        gap: 40px;
        padding: 60px 24px;
    `}
	${media.xl`
        gap: 35px;  
        display: grid;
        grid-template-columns: 0.45fr 0.55fr;
        padding: 135px 80px;
        `}
    ${media.xxl`
        padding: 135px 0;
        max-width: 1460px;
        margin: 0 auto;
    `}
`;

const LeftImage = styled.div`
	height: 206px;
	position: absolute;
	left: 0;
	top: -20px;
	${media.md`
        height: 340px;
        top: -40px;
    `}
	${media.xl`
        height: 450px;
        top: 60px;
        left: -150px;
    `}
    ${media.xxl`
        left: -100px;
    `}
	> img {
		width: auto;
		height: 100%;
	}
`;

const RightImage = styled.div`
	height: 206px;
	position: absolute;
	right: 0;
	bottom: -20px;
	${media.md`
        height: 340px;
        bottom: -40px;
    `}
	${media.xl`
        height: 450px;
        bottom: 60px;
        right: -170px;
    `}
    ${media.xxl`
        right: -100px;
    `}
	> img {
		width: auto;
		height: 100%;
	}
`;
