import React from 'react';
import styled, { css } from 'styled-components';

import { media, transition } from '../../styles/mixins';

export const DistilItems = ({ data }) => {
	return (
		<Wrapper>
			<Items>
				{data.items.map((item) => (
					<Item key={item.title}>
						<Title>{item.title}</Title>
						<Text>{item.text}</Text>
					</Item>
				))}
			</Items>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	padding: 40px 16px;
	${media.md`
        padding: 60px 24px;
    `}
	${media.xl`
        padding: 160px 80px;
    `}
    ${media.xxl`
        padding: 160px 0;
        max-width: 1460px;
        margin: 0 auto;
    `}
`;

const Items = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
	${media.md`
        gap: 8px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    `}
`;

const Item = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	${transition('all')};
	border-radius: 24px;
	gap: 4px;
	&:hover {
		background: ${(p) => p.theme.colors.green};
		p {
			color: ${(p) => p.theme.colors.white};
		}
	}
	${media.xl`
        padding: 24px;
        gap: 8px;
    `}
`;

const Title = styled.p`
	font-weight: 600;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t6};
	`}
	line-height: 40px;
	color: ${(p) => p.theme.colors.headerText};
	letter-spacing: 0.01em;
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h3};
		`}
		line-height: 48px;
	`}
	${transition('all')};
`;

const Text = styled.p`
	font-weight: 400;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t2};
	`}
	line-height: 20px;
	color: ${(p) => p.theme.colors.gray6};
	letter-spacing: 0.01em;
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t3};
		`}
		line-height: 22px;
        width: 80%;
        margin: 0 auto;
	`}
	${transition('all')};
	text-align: center;
`;
