import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '../../styles/mixins';
import { SkImage } from '../shared/image';

export const DistilSolutions = ({ data, images }) => {
	return (
		<Wrapper>
			<MainTitle>{data.title}</MainTitle>
			<Items>
				{data.items.map((item, index) => (
					<Row key={item.title} data-type={item.type}>
						<Item
							data-type={item.type}
							data-color={item?.color && item.color}
							data-left={(index + 1) % 2 === 0 ? 'true' : 'false'}
						>
							{item.type === 'default' && (
								<>
									<TextWrapper>
										<Title>{item.title}</Title>
										<Subtitle>{item.subtitle}</Subtitle>
										<Text>{item.text}</Text>
									</TextWrapper>
									<ImageWrapper data-left={(index + 1) % 2 === 0 ? 'true' : 'false'}>
										<SkImage {...images.find((image) => image.name === item.image).image} />
									</ImageWrapper>
								</>
							)}
							{(item.type === 'blue' || item.type === 'gray') && (
								<>
									<TextWrapper>
										<Title>{item.title}</Title>
										<Subtitle>{item.subtitle}</Subtitle>
										<Text>{item.text}</Text>
									</TextWrapper>
									<SkImage {...images.find((image) => image.name === item.image).image} />
								</>
							)}
							{item.type === 'black' && (
								<>
									<TextWrapper>
										<Title>{item.title}</Title>
										<Subtitle>{item.subtitle}</Subtitle>
										<Text>{item.text}</Text>
									</TextWrapper>
									<BlackImage>
										<SkImage {...images.find((image) => image.name === item.image).image} />
									</BlackImage>
								</>
							)}
						</Item>
					</Row>
				))}
			</Items>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: 40px 0 0 0;
	gap: 24px;
	${media.md`
		padding: 60px 0 0 0;
        gap: 40px;
	`}
	${media.xl`
		padding: 80px 0 0 0;
        gap: 60px;
	`}
`;

const MainTitle = styled.p`
	font-weight: 600;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h4};
	`}
	line-height: 32px;
	color: ${(p) => p.theme.colors.headerText};
	letter-spacing: 0.01em;
	${media.md`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h3};
		`}
		line-height: 48px;
	`}
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h1};
		`}
		line-height: 88px;
	`}
	width: 100%;
	text-align: center;
`;

const Items = styled.div`
	display: flex;
	flex-direction: column;
`;

const Row = styled.div`
	border-radius: 24px;
	${media.xl`
		border-radius: 40px;
	`}
	&[data-type='blue'] {
		background: ${(p) => p.theme.colors.blue};
	}
	&[data-type='gray'] {
		background: ${(p) => p.theme.colors.lightGreen};
	}
`;

const Item = styled.div`
	&[data-type='gray'] {
		padding: 40px 16px;
		box-sizing: border-box;
		display: flex;
		flex-direction: column-reverse;
		gap: 24px;
		> img {
			width: 260px;
			height: auto;
			margin: 0 auto;
		}
		${media.md`
			width: 460px;
			margin: 60px auto;
			padding: 0;
			gap: 40px;
			> img {
				width: 340px;
			}
		`}
		${media.xl`
			width: 100%;
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 200px;
			padding: 75px 80px;
			> div:first-child{
				height: fit-content;
    			margin: auto 0;
			}
			> img {
				width: 440px;
			}
		`}
		${media.xxl`
			padding: 75px 0;
			margin: 0 auto;
			max-width: 1460px;
		`}
	}
	&[data-type='default'] {
		display: flex;
		flex-direction: column-reverse;
		> div:first-child {
			margin: 16px;
		}
		${media.md`
			display: flex;
			flex-direction: row;
			&[data-left='true'] {
				flex-direction: row-reverse;
			}
			> div:first-child {
				height: fit-content;
				margin: auto 24px;
			}
		`}
		${media.xl`
			> div:first-child {
				height: fit-content;
				margin: auto 80px;
			}
		`}
		${media.xxl`
			> div:first-child {
				height: fit-content;
				margin: auto 80px auto calc( ( 100vw - 1460px ) / 2);
			}
			&[data-left='true'] {
				> div:first-child {
					height: fit-content;
					margin: auto calc( ( 100vw - 1460px ) / 2) auto 80px;
				}
			}
		`}
	}
	&[data-type='black'] {
		display: flex;
		flex-direction: column-reverse;
		> div:first-child {
			padding: 16px;
			background: ${(p) => p.theme.colors.dark};
			border-radius: 0 0 24px 24px;
			p {
				color: ${(p) => p.theme.colors.white};
			}
			p:first-child {
				background: ${(p) => p.theme.colors.thansparentLightWhite};
			}
			p:last-child {
				color: ${(p) => p.theme.colors.lightGray};
			}
		}
		${media.md`
			display: flex;
			flex-direction: row;
			&[data-left='true'] {
				flex-direction: row-reverse;
			}
			> div:first-child {
				padding: 24px;
				width: 50%;
				min-height: 100%;
				border-radius: 24px;
				justify-content: center;
			}
		`}
		${media.xl`
			> div:first-child {
				border-radius: 40px;
				min-width: 50%;
				padding: 80px;
			}
		`}
		${media.xxl`
		> div:first-child {
			padding: 0 80px 0 calc( ( 100vw - 1460px ) / 2);
			}
			&[data-left='true'] {
				> div:first-child {
					padding: 0 calc( ( 100vw - 1460px ) / 2) 0 80px;
				}
			}
		`}
	}
	&[data-type='blue'] {
		padding: 40px 16px;
		box-sizing: border-box;
		display: flex;
		flex-direction: column-reverse;
		p {
			color: ${(p) => p.theme.colors.white};
		}
		p:last-child {
			color: ${(p) => p.theme.colors.superlightGray};
		}
		${media.md`
			width: 460px;
			margin: 60px auto;
			padding: 0;
		`}
		${media.xl`
			width: 100%;
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 200px;
			padding: 75px 80px;
			> div:first-child{
				height: fit-content;
    			margin: auto 0;
			}
		`}
		${media.xxl`
			padding: 75px 0;
			margin: 0 auto;
			max-width: 1460px;
		`}
	}
`;

const TextWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
	${media.md`
		gap: 16px;
	`}
`;

const Title = styled.p`
	font-weight: 600;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h5};
	`}
	border-radius: 16px;
	padding: 16px;
	box-sizing: border-box;
	line-height: 28px;
	margin-bottom: 4px;
	color: ${(p) => p.theme.colors.headerText};
	letter-spacing: 0.01em;
	${media.md`
		margin-bottom: 8px;
	`}
	${media.xl`
		padding: 24px;
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t6};
		`}
		line-height: 40px;
	`}
	background: ${(p) => p.theme.colors.transparentGray};
`;

const Subtitle = styled.p`
	font-weight: 500;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t3};
	`}
	line-height: 22px;
	color: ${(p) => p.theme.colors.headerText};
	letter-spacing: 0.01em;
	padding: 0 16px;
	${media.xl`
		padding: 0 24px;
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t4};
		`}
		line-height: 24px;
	`}
`;

const Text = styled.p`
	font-weight: 400;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t2};
	`}
	line-height: 20px;
	color: ${(p) => p.theme.colors.gray};
	letter-spacing: 0.01em;
	padding: 0 16px;
	${media.xl`
		padding: 0 24px;
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t3};
		`}
		line-height: 22px;
	`}
`;

const ImageWrapper = styled.div`
	height: 320px;
	position: relative;
	> img {
		height: 100%;
		position: absolute;
		left: 16px;
		width: auto;
		max-width: fit-content;
		@media (min-width: 530px) and (max-width: 767px) {
			right: -16px;
			left: auto;
		}
	}
	${media.md`
		min-width: 50%;
    	width: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 470px;
		overflow: hidden;
		border-radius: 24px 0 0 24px;
		> img {
			position: initial;
		}
	`}
	${media.xl`
		min-width: 57%;
    	width: 57%;
		height: 640px;
		max-height: 640px;
		border-radius: 40px;
	`}
	&[data-left='true'] {
		display: flex;
		justify-content: end;
		${media.md`
			justify-content: center;
			border-radius: 0px 24px 24px 0px;
		`}
		${media.xl`
			border-radius: 40px;
		`}
		> img {
			left: auto;
			right: 16px;
			@media (min-width: 530px) and (max-width: 767px) {
				right: auto;
				left: -16px;
			}
		}
	}
`;

const BlackImage = styled.div`
	height: 400px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 24px 24px 0px 0px;
	overflow: hidden;
	> img {
		height: 100%;
		width: auto;
		max-width: fit-content;
	}
	@media (min-width: 430px) and (max-width: 767px) {
		> img {
			height: 650px;
			width: auto;
		}
	}
	${media.md`
		width: 50%;
		height: 480px;
		border-radius: 24px;
	`}
	${media.xl`
		width: auto;
		height: 800px;
		border-radius: 40px;
	`}
`;
