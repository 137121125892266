import * as React from 'react';

import { Enum_Section_Sectionposition, SectionEntity } from '@schema';
import { Section } from '@templates/section';

const SectionRendererTemplate = ({ section }: { section: SectionEntity }) => {
	switch (section.attributes.sectionPosition) {
		case Enum_Section_Sectionposition.Top:
			return <Section.Top {...section.attributes} />;
		case Enum_Section_Sectionposition.Middle:
			return <Section.Middle {...section.attributes} />;
		case Enum_Section_Sectionposition.Bottom:
			return <Section.Bottom {...section.attributes} />;
		default:
			return <Section.Middle {...section.attributes} />;
	}
};

export default SectionRendererTemplate;
