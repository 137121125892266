import React from 'react';
import styled, { css } from 'styled-components';

import { media, transition } from '../../styles/mixins';
import { SkImage } from '../shared/image';
import { RemixIcon } from '../shared/remix-icon';

export const DistilAbout = ({ data, images }) => {
	return (
		<Wrapper>
			<Title>{data.title}</Title>
			<Top>
				<Left>
					<ImageWrapper>
						<SkImage {...images.find((image) => image.name === data.image).image} />
					</ImageWrapper>
					<ImageTitle>{data.logo_title}</ImageTitle>
					<ImageText>{data.logo_text}</ImageText>
				</Left>
				<Right>
					<Text>{data.text}</Text>
					<Items>
						{data.items.map((item) => (
							<Item key={item.title}>
								<IconWrapper>
									<RemixIcon className={item.icon} />
								</IconWrapper>
								<ItemContent>
									<ItemTitle>{item.title}</ItemTitle>
									<Description>{item.description}</Description>
								</ItemContent>
							</Item>
						))}
					</Items>
				</Right>
			</Top>
			<Bot>
				{data.cards.map((card) => (
					<Card key={card.text}>
						<CardTop>
							<CardTitle>{card.value}</CardTitle>
							{card.image && (
								<CardImage>
									<SkImage {...images.find((image) => image.name === card.image)?.image} />
								</CardImage>
							)}
						</CardTop>
						<CardText>{card.text}</CardText>
					</Card>
				))}
			</Bot>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: 80px 16px 0px 16px;
	gap: 24px;
	${media.md`
		padding: 60px 24px;
        gap: 40px;
	`}
	${media.xl`
		padding: 120px 80px 80px 80px;
        gap: 60px;
	`}
	${media.xxl`
		padding: 120px 0 80px 0;
		margin: 0 auto;
		max-width: 1460px;
	`}
`;

const Top = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
	${media.md`
		gap: 40px;
	`}
	${media.xl`
		gap: 203px;
		flex-direction: row;
		align-items: center;
	`}
`;

const Bot = styled.div`
	display: grid;
	gap: 16px;
	grid-template-columns: repeat(2, 1fr);
	${media.md`
        grid-template-columns: repeat(3, 1fr);
        gap: 24px;
	`}
	${media.xl`
        gap: 8px;
    `}
`;

const Items = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	${media.md`
		flex-direction: row;
		justify-content: space-between;
	`}
`;

const Left = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	padding: 0 20px;
	${media.md`
        padding: 0;
		width: 285px;
		min-width: 285px;
		margin: 0 auto;
    `}
	${media.xl`
        padding: 0;
		margin: 0;
    `}
`;

const Right = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
	${media.xl`
		gap: 40px;
	`}
`;

const ImageWrapper = styled.div`
	width: 140px;
	margin: 0 auto;
	margin-bottom: 16px;
	${media.md`
		width: 200px;
	`}
`;

const Title = styled.p`
	font-weight: 600;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h4};
	`}
	line-height: 32px;
	color: ${(p) => p.theme.colors.headerText};
	letter-spacing: 0.01em;
	${media.md`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h3};
		`}
		line-height: 48px;
	`}
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h1};
		`}
		line-height: 88px;
	`}
	width: 100%;
	text-align: center;
`;

const Text = styled.p`
	font-weight: 400;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t2};
	`}
	line-height: 20px;
	color: ${(p) => p.theme.colors.headerText};
	${media.md`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t3};
		`}
		line-height: 22px;
	`}
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t4};
		`}
		line-height: 24px;
	`}
`;

const ImageTitle = styled.p`
	font-weight: 600;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h4};
	`}
	line-height: 32px;
	letter-spacing: 0.01em;
	color: ${(p) => p.theme.colors.headerText};
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t6};
		`}
		line-height: 40px;
	`}
	width: 100%;
	text-align: center;
`;

const ImageText = styled.p`
	font-weight: 400;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t2};
	`}
	line-height: 20px;
	color: ${(p) => p.theme.colors.gray6};
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t3};
		`}
		line-height: 22px;
	`}
	width: 100%;
	text-align: center;
`;

const Card = styled.div`
	display: flex;
	flex-direction: column;
	border-radius: 24px;
	gap: 4px;
	${media.xl`
		padding: 24px;
	`}
	&:hover {
		${media.xl`
			${(p) => css`
				background: ${(p) => p.theme.colors.green};
			`}
		`}
		p {
			${media.xl`
				${(p) => css`
					color: ${(p) => p.theme.colors.white};
				`}
			`}
		}
	}
	${transition('all')};
`;

const CardTop = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;

const CardTitle = styled.p`
	font-weight: 600;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t6};
	`}
	line-height: 40px;
	letter-spacing: 0.01em;
	color: ${(p) => p.theme.colors.headerText};
	${media.xl`
		font-weight: 500;
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h3};
		`}
		line-height: 48px;
	`}
	${transition('all')};
`;

const CardText = styled.p`
	font-weight: 400;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t2};
	`}
	line-height: 20px;
	color: ${(p) => p.theme.colors.gray6};
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t3};
		`}
		line-height: 22px;
	`}
	${transition('all')};
`;

const CardImage = styled.div`
	width: 50px;
	${media.xl`
		width: 65px;
	`}
`;

const Item = styled.div`
	display: flex;
	flex-direction: row;
	gap: 12px;
	height: 48px;
	width: 100%;
`;

const Description = styled.p`
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t3};
	`}
	line-height: 22px;
	color: ${(p) => p.theme.colors.gray6};
	${media.md`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.t4};
				`}
                line-height: 24px;
    `}
	color: ${(p) => p.theme.colors.headerText};
`;

const IconWrapper = styled.div`
	background: ${(p) => p.theme.colors.superlightGray};
	color: ${(p) => p.theme.colors.green};
	width: 48px;
	height: 48px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 48px;
	margin-bottom: 8px;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t5};
	`}
`;

const ItemContent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

const ItemTitle = styled.p`
	font-weight: 500;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t2};
	`}
	line-height: 20px;
	${media.md`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.t3};
				`}
                line-height: 22px;
    `}
	color: ${(p) => p.theme.colors.gray6};
`;
