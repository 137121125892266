import React from 'react';
import styled, { css } from 'styled-components';

import { media, transition } from '../../styles/mixins';
import { Breadcrumbs } from '../breadcrumbs';
import { SkImage } from '../shared/image';
import { Link } from '../shared/link';

export const DistilHero = ({ data, images, path }) => {
	return (
		<Container>
			<Breadcrumbs isWrapped slug={path || '/case-studies/distil.ai'} />
			<Wrapper>
				<TextWrapper>
					<BigCircle />
					<SmallCircle />
					<TitleWrapper>
						<Icon>
							<SkImage {...images.find((image) => image.name === data.logo).image} />
						</Icon>
						<Title>{data.title}</Title>
					</TitleWrapper>
					<TagsWrapper>
						{data.tags.map((tag) => (
							<Tag key={tag}>{tag}</Tag>
						))}
					</TagsWrapper>
					<Description>{data.description}</Description>
					<LinkWrapper>
						<Link label={data.link.label} remixIcon={data.link.icon} url={data.link.url} />
					</LinkWrapper>
				</TextWrapper>
				<ImageWrapper>
					<SkImage {...images.find((image) => image.name === data.side_image).image} />
				</ImageWrapper>
			</Wrapper>
		</Container>
	);
};

const Container = styled.div`
	position: relative;
`;

const SmallCircle = styled.div`
	height: 40px;
	width: 40px;
	opacity: 0.5;
	background: ${(p) => p.theme.colors.green};
	position: absolute;
	border-radius: 50px;
	filter: blur(10px);
	left: 0;
	top: -60px;
	${media.md`
	    top: -140px;
	`}
	${media.xl`
        left: -50px;
	    top: -120px;
	`}
`;

const BigCircle = styled.div`
	height: 80px;
	width: 80px;
	opacity: 0.5;
	filter: blur(25px);
	background: ${(p) => p.theme.colors.green};
	position: absolute;
	border-radius: 50px;
	right: 8px;
	top: -40px;
	${media.md`
		right: 40px;
        top: -60px;
	`}
	${media.xl`
        inset: auto 50% -140px auto;
	`}
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column-reverse;
	padding: 0 16px;
	gap: 24px;
	${media.md`
		padding: 0 24px;
        gap: 32px;
	`}
	${media.xl`
		padding: 0 80px;
		display: grid;
		grid-template-columns: 3.1fr 2fr;
        gap: 0;
        height: 960px;
	`}
	${media.xxl`
		padding: 0;
		margin: 0 auto;
		max-width: 1460px;
	`}
`;

const TextWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: relative;
	gap: 16px;
	${media.xl`
		gap: 24px;
        height: fit-content;
        margin: auto 0;
	`}
`;

const TitleWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 12px;
	${media.md`
        gap: 16px;
	`}
	${media.xl`
    	gap: 24px;
		margin-bottom: 16px;
	`}
`;

const Title = styled.p`
	font-weight: 600;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h4};
	`}
	line-height: 32px;
	color: ${(p) => p.theme.colors.headerText};
	letter-spacing: 0.01em;
	${media.md`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h3};
		`}
		line-height: 48px;
	`}
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h2};
		`}
		line-height: 72px;
	`}
`;

const Description = styled.p`
	font-weight: 400;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t3};
	`}
	line-height: 22px;
	color: ${(p) => p.theme.colors.headerText};
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t4};
		`}
		line-height: 24px;
	`}
`;

const LinkWrapper = styled.div`
	> a {
		background: ${(p) => p.theme.colors.gray6};
		font-weight: 500;
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h6};
		`}
		line-height:22px;
		&:hover {
			background: ${(p) => p.theme.colors.green};
			gap: 8px;
		}
	}
`;

const TagsWrapper = styled.div`
	display: flex;
	flex-direction: row;
	gap: 8px;
	flex-wrap: wrap;
`;

const Tag = styled.div`
	padding: 6px 12px;
	color: ${(p) => p.theme.colors.gray};
	font-weight: 400;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t1};
	`}
	line-height: 16px;
	background: ${(p) => p.theme.colors.superlightGray};
	${transition('all')};
	&:hover {
		background: ${(p) => p.theme.colors.lightGray};
		color: ${(p) => p.theme.colors.headerText};
	}
	border-radius: 40px;
	${media.md`
		padding: 7 16px;
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t3};
		`}
		line-height: 22px;
	`}
`;

const Icon = styled.div`
	width: 40px;
	> img {
		width: 100%;
	}
	${media.md`
		width: 48px;
	`}
	${media.xl`
		width: 76px;
	`}
`;

const ImageWrapper = styled.div`
	height: 500px;
	position: relative;
	> img {
		width: auto;
		height: 100%;
		max-width: fit-content;
		position: absolute;
		left: -25%;
	}
	${media.md`
		width: 100%;
        height: fit-content;
        > img {
            position: initial;
            width: 100%;
            height: auto;
        }
	`}
	${media.xl`
        position: absolute;
        height: 100%;
        width: fit-content;
        right: -60%;
        > img {
            height: 100%;
            width: auto;
        }
	`}
    @media (min-width: 1340px) and (max-width: 1599px) {
		right: -40%;
	}
	${media.xxl`
        right: -35%;
    `}
`;
